html{
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  line-height: 18px;
  letter-spacing: 0.5px;
}

bold, b{
  font-weight: 600;
}

*{
  box-sizing: border-box;
}

a, a:hover{
  text-decoration: none;
}
